// TODO, SHOP and APP should be removed, all keys should go to react.shared

import enReactAdmin from 'locales/en/react/admin.yml'
import enReactCabinet from 'locales/en/react/cabinet.yml'
import enReactCommon from 'locales/en/react/common.yml'
import enReactPayer from 'locales/en/react/payer.yml'
import enReactShared from 'locales/en/react/shared.yml'
import enReactWidget from 'locales/en/front/widgets.yml'
import enReactSalesTeamMember from 'locales/en/react/sales_team_member.yml'

import deReactAdmin from 'locales/de/react/admin.yml'
import deReactCabinet from 'locales/de/react/cabinet.yml'
import deReactCommon from 'locales/de/react/common.yml'
import deReactPayer from 'locales/de/react/payer.yml'
import deReactShared from 'locales/de/react/shared.yml'
import deReactWidget from 'locales/de/front/widgets.yml'
import deReactSalesTeamMember from 'locales/de/react/sales_team_member.yml'

import itReactAdmin from 'locales/it/react/admin.yml'
import itReactCabinet from 'locales/it/react/cabinet.yml'
import itReactCommon from 'locales/it/react/common.yml'
import itReactPayer from 'locales/it/react/payer.yml'
import itReactShared from 'locales/it/react/shared.yml'
import itReactWidget from 'locales/it/front/widgets.yml'
import itReactSalesTeamMember from 'locales/it/react/sales_team_member.yml'

import plReactAdmin from 'locales/pl/react/admin.yml'
import plReactCabinet from 'locales/pl/react/cabinet.yml'
import plReactCommon from 'locales/pl/react/common.yml'
import plReactPayer from 'locales/pl/react/payer.yml'
import plReactShared from 'locales/pl/react/shared.yml'
import plReactWidget from 'locales/pl/front/widgets.yml'
import plReactSalesTeamMember from 'locales/pl/react/sales_team_member.yml'

import svReactAdmin from 'locales/sv/react/admin.yml'
import svReactCabinet from 'locales/sv/react/cabinet.yml'
import svReactCommon from 'locales/sv/react/common.yml'
import svReactPayer from 'locales/sv/react/payer.yml'
import svReactShared from 'locales/sv/react/shared.yml'
import svReactWidget from 'locales/sv/front/widgets.yml'
import svReactSalesTeamMember from 'locales/sv/react/sales_team_member.yml'

import csReactAdmin from 'locales/cs/react/admin.yml'
import csReactCabinet from 'locales/cs/react/cabinet.yml'
import csReactCommon from 'locales/cs/react/common.yml'
import csReactPayer from 'locales/cs/react/payer.yml'
import csReactShared from 'locales/cs/react/shared.yml'
import csReactWidget from 'locales/cs/front/widgets.yml'
import csReactSalesTeamMember from 'locales/cs/react/sales_team_member.yml'

import daReactAdmin from 'locales/da/react/admin.yml'
import daReactCabinet from 'locales/da/react/cabinet.yml'
import daReactCommon from 'locales/da/react/common.yml'
import daReactPayer from 'locales/da/react/payer.yml'
import daReactShared from 'locales/da/react/shared.yml'
import daReactWidget from 'locales/da/front/widgets.yml'
import daReactSalesTeamMember from 'locales/da/react/sales_team_member.yml'

import huReactAdmin from 'locales/hu/react/admin.yml'
import huReactCabinet from 'locales/hu/react/cabinet.yml'
import huReactCommon from 'locales/hu/react/common.yml'
import huReactPayer from 'locales/hu/react/payer.yml'
import huReactShared from 'locales/hu/react/shared.yml'
import huReactWidget from 'locales/hu/front/widgets.yml'
import huReactSalesTeamMember from 'locales/hu/react/sales_team_member.yml'

import arReactAdmin from 'locales/ar/react/admin.yml'
import arReactCabinet from 'locales/ar/react/cabinet.yml'
import arReactCommon from 'locales/ar/react/common.yml'
import arReactPayer from 'locales/ar/react/payer.yml'
import arReactShared from 'locales/ar/react/shared.yml'
import arReactWidget from 'locales/ar/front/widgets.yml'
import arReactSalesTeamMember from 'locales/ar/react/sales_team_member.yml'

import elReactAdmin from 'locales/el/react/admin.yml'
import elReactCabinet from 'locales/el/react/cabinet.yml'
import elReactCommon from 'locales/el/react/common.yml'
import elReactPayer from 'locales/el/react/payer.yml'
import elReactShared from 'locales/el/react/shared.yml'
import elReactWidget from 'locales/el/front/widgets.yml'
import elReactSalesTeamMember from 'locales/el/react/sales_team_member.yml'

import esReactAdmin from 'locales/es/react/admin.yml'
import esReactCabinet from 'locales/es/react/cabinet.yml'
import esReactCommon from 'locales/es/react/common.yml'
import esReactPayer from 'locales/es/react/payer.yml'
import esReactShared from 'locales/es/react/shared.yml'
import esReactWidget from 'locales/es/front/widgets.yml'
import esReactSalesTeamMember from 'locales/es/react/sales_team_member.yml'

import ptBrReactAdmin from 'locales/pt_br/react/admin.yml'
import ptBrReactCabinet from 'locales/pt_br/react/cabinet.yml'
import ptBrReactCommon from 'locales/pt_br/react/common.yml'
import ptBrReactPayer from 'locales/pt_br/react/payer.yml'
import ptBrReactShared from 'locales/pt_br/react/shared.yml'
import ptBrReactWidget from 'locales/pt_br/front/widgets.yml'
import ptBrReactSalesTeamMember from 'locales/pt_br/react/sales_team_member.yml'

import ptPtReactAdmin from 'locales/pt_pt/react/admin.yml'
import ptPtReactCabinet from 'locales/pt_pt/react/cabinet.yml'
import ptPtReactCommon from 'locales/pt_pt/react/common.yml'
import ptPtReactPayer from 'locales/pt_pt/react/payer.yml'
import ptPtReactShared from 'locales/pt_pt/react/shared.yml'
import ptPtReactWidget from 'locales/pt_pt/front/widgets.yml'
import ptPtReactSalesTeamMember from 'locales/pt_pt/react/sales_team_member.yml'

import trReactAdmin from 'locales/tr/react/admin.yml'
import trReactCabinet from 'locales/tr/react/cabinet.yml'
import trReactCommon from 'locales/tr/react/common.yml'
import trReactPayer from 'locales/tr/react/payer.yml'
import trReactShared from 'locales/tr/react/shared.yml'
import trReactWidget from 'locales/tr/front/widgets.yml'
import trReactSalesTeamMember from 'locales/tr/react/sales_team_member.yml'

import frReactAdmin from 'locales/fr/react/admin.yml'
import frReactCabinet from 'locales/fr/react/cabinet.yml'
import frReactCommon from 'locales/fr/react/common.yml'
import frReactPayer from 'locales/fr/react/payer.yml'
import frReactShared from 'locales/fr/react/shared.yml'
import frReactWidget from 'locales/fr/front/widgets.yml'
import frReactSalesTeamMember from 'locales/fr/react/sales_team_member.yml'

import roReactAdmin from 'locales/ro/react/admin.yml'
import roReactCabinet from 'locales/ro/react/cabinet.yml'
import roReactCommon from 'locales/ro/react/common.yml'
import roReactPayer from 'locales/ro/react/payer.yml'
import roReactShared from 'locales/ro/react/shared.yml'
import roReactWidget from 'locales/ro/front/widgets.yml'
import roReactSalesTeamMember from 'locales/ro/react/sales_team_member.yml'

import hrReactAdmin from 'locales/hr/react/admin.yml'
import hrReactCabinet from 'locales/hr/react/cabinet.yml'
import hrReactCommon from 'locales/hr/react/common.yml'
import hrReactPayer from 'locales/hr/react/payer.yml'
import hrReactShared from 'locales/hr/react/shared.yml'
import hrReactWidget from 'locales/hr/front/widgets.yml'
import hrReactSalesTeamMember from 'locales/hr/react/sales_team_member.yml'

import nlReactAdmin from 'locales/nl/react/admin.yml'
import nlReactCabinet from 'locales/nl/react/cabinet.yml'
import nlReactCommon from 'locales/nl/react/common.yml'
import nlReactPayer from 'locales/nl/react/payer.yml'
import nlReactShared from 'locales/nl/react/shared.yml'
import nlReactWidget from 'locales/nl/front/widgets.yml'
import nlReactSalesTeamMember from 'locales/nl/react/sales_team_member.yml'

import noReactAdmin from 'locales/no/react/admin.yml'
import noReactCabinet from 'locales/no/react/cabinet.yml'
import noReactCommon from 'locales/no/react/common.yml'
import noReactPayer from 'locales/no/react/payer.yml'
import noReactShared from 'locales/no/react/shared.yml'
import noReactWidget from 'locales/no/front/widgets.yml'
import noReactSalesTeamMember from 'locales/no/react/sales_team_member.yml'


I18n.translations.en = I18n.extend((I18n.translations.en || {}), {
  react: {
    admin: enReactAdmin,
    cabinet: enReactCabinet,
    common: enReactCommon,
    payer: enReactPayer,
    shared: enReactShared,
    sales_team_member: enReactSalesTeamMember
  },
  front: {
    widgets: enReactWidget
  }
})

I18n.translations.de = I18n.extend((I18n.translations.de || {}), {
  react: {
    admin: deReactAdmin,
    cabinet: deReactCabinet,
    common: deReactCommon,
    payer: deReactPayer,
    shared: deReactShared,
    sales_team_member: deReactSalesTeamMember
  },
  front: {
    widgets: deReactWidget
  }
})

I18n.translations.it = I18n.extend((I18n.translations.it || {}), {
  react: {
    admin: itReactAdmin,
    cabinet: itReactCabinet,
    common: itReactCommon,
    payer: itReactPayer,
    shared: itReactShared,
    sales_team_member: itReactSalesTeamMember
  },
  front: {
    widgets: itReactWidget
  }
})

I18n.translations.pl = I18n.extend((I18n.translations.pl || {}), {
  react: {
    admin: plReactAdmin,
    cabinet: plReactCabinet,
    common: plReactCommon,
    payer: plReactPayer,
    shared: plReactShared,
    sales_team_member: plReactSalesTeamMember
  },
  front: {
    widgets: plReactWidget
  }
})

I18n.translations.sv = I18n.extend((I18n.translations.sv || {}), {
  react: {
    admin: svReactAdmin,
    cabinet: svReactCabinet,
    common: svReactCommon,
    payer: svReactPayer,
    shared: svReactShared,
    sales_team_member: svReactSalesTeamMember
  },
  front: {
    widgets: svReactWidget
  }
})

I18n.translations.cs = I18n.extend((I18n.translations.cs || {}), {
  react: {
    admin: csReactAdmin,
    cabinet: csReactCabinet,
    common: csReactCommon,
    payer: csReactPayer,
    shared: csReactShared,
    sales_team_member: csReactSalesTeamMember
  },
  front: {
    widgets: csReactWidget
  }
})

I18n.translations.da = I18n.extend((I18n.translations.da || {}), {
  react: {
    admin: daReactAdmin,
    cabinet: daReactCabinet,
    common: daReactCommon,
    payer: daReactPayer,
    shared: daReactShared,
    sales_team_member: daReactSalesTeamMember
  },
  front: {
    widgets: daReactWidget
  }
})

I18n.translations.hu = I18n.extend((I18n.translations.hu || {}), {
  react: {
    admin: huReactAdmin,
    cabinet: huReactCabinet,
    common: huReactCommon,
    payer: huReactPayer,
    shared: huReactShared,
    sales_team_member: huReactSalesTeamMember
  },
  front: {
    widgets: huReactWidget
  }
})

I18n.translations.ar = I18n.extend((I18n.translations.ar || {}), {
  react: {
    admin: arReactAdmin,
    cabinet: arReactCabinet,
    common: arReactCommon,
    payer: arReactPayer,
    shared: arReactShared,
    sales_team_member: arReactSalesTeamMember
  },
  front: {
    widgets: arReactWidget
  }
})

I18n.translations.el = I18n.extend((I18n.translations.el || {}), {
  react: {
    admin: elReactAdmin,
    cabinet: elReactCabinet,
    common: elReactCommon,
    payer: elReactPayer,
    shared: elReactShared,
    sales_team_member: elReactSalesTeamMember
  },
  front: {
    widgets: elReactWidget
  }
})

I18n.translations.es = I18n.extend((I18n.translations.es || {}), {
  react: {
    admin: esReactAdmin,
    cabinet: esReactCabinet,
    common: esReactCommon,
    payer: esReactPayer,
    shared: esReactShared,
    sales_team_member: esReactSalesTeamMember
  },
  front: {
    widgets: esReactWidget
  }
})

I18n.translations.pt_br = I18n.extend((I18n.translations.pt_br || {}), {
  react: {
    admin: ptBrReactAdmin,
    cabinet: ptBrReactCabinet,
    common: ptBrReactCommon,
    payer: ptBrReactPayer,
    shared: ptBrReactShared,
    sales_team_member: ptBrReactSalesTeamMember
  },
  front: {
    widgets: ptBrReactWidget
  }
})

I18n.translations.pt_pt = I18n.extend((I18n.translations.pt_pt || {}), {
  react: {
    admin: ptPtReactAdmin,
    cabinet: ptPtReactCabinet,
    common: ptPtReactCommon,
    payer: ptPtReactPayer,
    shared: ptPtReactShared,
    sales_team_member: ptPtReactSalesTeamMember
  },
  front: {
    widgets: ptPtReactWidget
  }
})

I18n.translations.tr = I18n.extend((I18n.translations.tr || {}), {
  react: {
    admin: trReactAdmin,
    cabinet: trReactCabinet,
    common: trReactCommon,
    payer: trReactPayer,
    shared: trReactShared,
    sales_team_member: trReactSalesTeamMember
  },
  front: {
    widgets: trReactWidget
  }
})

I18n.translations.fr = I18n.extend((I18n.translations.fr || {}), {
  react: {
    admin: frReactAdmin,
    cabinet: frReactCabinet,
    common: frReactCommon,
    payer: frReactPayer,
    shared: frReactShared,
    sales_team_member: frReactSalesTeamMember
  },
  front: {
    widgets: frReactWidget
  }
})

I18n.translations.ro = I18n.extend((I18n.translations.ro || {}), {
  react: {
    admin: roReactAdmin,
    cabinet: roReactCabinet,
    common: roReactCommon,
    payer: roReactPayer,
    shared: roReactShared,
    sales_team_member: roReactSalesTeamMember
  },
  front: {
    widgets: roReactWidget
  }
})

I18n.translations.hr = I18n.extend((I18n.translations.hr || {}), {
  react: {
    admin: hrReactAdmin,
    cabinet: hrReactCabinet,
    common: hrReactCommon,
    payer: hrReactPayer,
    shared: hrReactShared,
    sales_team_member: hrReactSalesTeamMember
  },
  front: {
    widgets: hrReactWidget
  }
})

I18n.translations.no = I18n.extend((I18n.translations.no || {}), {
  react: {
    admin: noReactAdmin,
    cabinet: noReactCabinet,
    common: noReactCommon,
    payer: noReactPayer,
    shared: noReactShared,
    sales_team_member: noReactSalesTeamMember
  },
  front: {
    widgets: noReactWidget
  }
})

I18n.translations.nl = I18n.extend((I18n.translations.nl || {}), {
  react: {
    admin: nlReactAdmin,
    cabinet: nlReactCabinet,
    common: nlReactCommon,
    payer: nlReactPayer,
    shared: nlReactShared,
    sales_team_member: nlReactSalesTeamMember
  },
  front: {
    widgets: nlReactWidget
  }
})